<template>
  <div class="order board_box">
    <div class="board_title dp_f">
      <div class="title">入库订单</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content">
      <el-table :data="tableData"  height="100%"   @row-click="checkGoods"   :row-class-name="tableRowClassName"
          :cell-style="{border:'none'}"
          :header-row-style="{border:'none'}"  class="zh-no-table-border"   style="width: 100%" v-if='tableData.length > 0'>
          <el-table-column width="100" type="index"  align="center" label="序号" />
          <el-table-column width="200"  align="center" label="入库订单" >
            <template #default="scope">
              <div class="table_text" >{{scope.row.orderSn}}</div>
            </template>
          </el-table-column>
          <el-table-column width="200"  align="center" label="供应商/客户" show-overflow-tooltip class-name="table_text2">
            <template #default="scope">{{scope.row.thirdName || '--'}}
            </template>
          </el-table-column>
          <el-table-column width="150" prop="unitName" align="center" label="产品数量" >
            <template #default="scope">
              <div class="table_text2" >{{scope.row.count}}</div>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="unitName" align="center" label="产品金额(元)" show-overflow-tooltip class-name="table_text">
            <template #default="scope">{{ $filters.toYuan(scope.row.totalPrice, '元') }}
            </template>
          </el-table-column>
          <el-table-column  prop="unitName" align="center" label="入库仓库"  show-overflow-tooltip class-name="table_text2">
            <template #default="scope">{{scope.row.stockName}}
            </template>
          </el-table-column>
          <el-table-column  prop="unitName" align="center" label="入库类型" >
            <template #default="scope">
              <div style="text-align: center;">
                <div
                  :class="[
                    'zh-label-text',
                    `${
                      isInOrder(scope.row.orderType) ? 'in-stock' : 'out-stock'
                    }`,
                    `order-type-${scope.row.orderType}`,
                  ]"
                >
                  {{ scope.row.orderTypeDesc }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column  prop="unitName" align="center" label="操作人" >
            <template #default="scope">
              <div class="table_text2" >{{scope.row.userName}}</div>
            </template>
          </el-table-column>
        <el-table-column prop="unitName" align="center" label="入库时间">
            <template #default="scope">
              <div class="table_text2" >{{$filters.timeFormat(scope.row.time,1)}}</div>
            </template>
          </el-table-column>
      </el-table>
     <div class="zh_empty" v-else></div>
    </div>
  </div>
     <!-- 订单详情 -->
     <OrderInfo
         ref="orderShow"
    ></OrderInfo>
    <OrderInfoAllocate
      v-model="orderInfoAllocateShow"
      @close="orderInfoAllocateShow = false"
      :orderNum="orderNum"
    ></OrderInfoAllocate>
</template>
<script setup>
import OrderInfo from "@/views/order/components/orderDetailHistory.vue";
import OrderInfoAllocate from '@/views/inbound/allocate/components/inStockInfo.vue'
import {tableRowClassName} from '@/utils/tool.js'
import {getCurrentInstance, onMounted, reactive, ref, toRefs, watch} from "vue";
import {stockOrderTop} from "@/utils/api/inbound/board.js";
import {useStore} from "vuex";
import {isInOrder} from "@utils/config";
import moment from 'moment'

const orderShow = ref(null);
const orderInfoAllocateShow = ref(false)
const store = useStore();
const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const orderNum = ref("1")
const unitId = ref(10);
const emit = defineEmits(["like"])
const { proxy } = getCurrentInstance();
const { formData, isLike } = toRefs(props);
const tableData = ref([])
watch(
  () => formData.value,
  (val) => {
    getData()
  },{
    deep:true
  }
);
const getData = () => {
  stockOrderTop({
       beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    tableData.value = res?.data?.list || []
  })
};
onMounted(()=>{
  getData()
})
const checkGoods = (e) => {
  orderNum.value = e.orderType + ''
  store.commit("PURCHASE_ORDER_INFO", e);
  orderShow.value.openDetails();
};
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>

<script>
import { reactive,watch,ref,toRefs } from "vue";
export default {
  name:'order',

}
</script>

<style lang="scss" scoped>
@import './index.scss';
  .order{
    width: 100%;
    height: 440px;
    .content{
      margin-top: 16px;
      height: calc(100% - 34px);
    }
  }
</style>
