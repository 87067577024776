<template>
  <div class="total board_box">
    <div class="board_title dp_f">
      <div class="title">入库产品类型分布</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <el-scrollbar v-if='tableData.length > 0' always>
      <div class="content dp_f cur_p">
        <template v-for='(item,index) in tableData' :key="item.orderTypeDesc">
          <el-progress type="circle" class="progress" :color="typeColor[index]" :stroke-width='10'
                       :percentage="item.percentageNum">
            <template #default="{ percentage }">
              <div class="percentage-label">{{ item.orderTypeDesc }}</div>
              <div class="percentage-value">{{ percentage }}%</div>
            </template>
          </el-progress>
        </template>
      </div>
    </el-scrollbar>
    <div class="content empty" v-else>
      <div class="zh_empty"></div>
    </div>
  </div>
</template>

<script setup>
import {typeColor} from "./config.js";
import {getCurrentInstance, onMounted, ref, toRefs, watch} from "vue";
import {stockType} from "@/utils/api/inbound/board.js";
import moment from 'moment'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const unitId = ref(11);
const emit = defineEmits(["like"])
const { proxy } = getCurrentInstance();
const { formData, isLike } = toRefs(props);
const tableData = ref([])
watch(
  () => formData.value,
  (val) => {
    getData()
  },{
    deep:true
  }
);
const getData = () => {
  stockType({
       beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    let list = res?.data?.list || []
    let sum = list.reduce((a, b) => {
      return a + b.value || 0
    }, 0)
    list.forEach(item=>{
      if (sum) {
        item.percentage = (item.value / sum * 100).toFixed(2)
        //percentageNum保留整数
        item.percentageNum = Math.round(item.percentage)
      } else {
        item.percentage = 0
        item.percentageNum = 0
      }
    })
    tableData.value = list
  })
};
onMounted(()=>{
  getData()
})
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>
<script>
export default {
  name:'total',

}
</script>

<style lang="scss" scoped>
@import './index.scss';
  .total{
    width: 49.5%;
    height: 296px;
    .content{
      margin-top: 60px;
      &.empty{
      display: block;
      height: 120px;
      }
      .progress{
        width: 112px;
        height: 112px;
        margin: 20px;
        .el-progress__text{
          .percentage-label{
             position: relative;
            right: -8px;
            top: 8px;
            text-align: center;
            font-size: 14px;
            color: #999999;
          }
          .percentage-value{
             position: relative;
              right: -8px;
              top: 8px;
            margin-top: 6px;
            text-align: center;
            font-size: 22px;
            color: #161829;
          }
        }
      }
    }
  }
</style>