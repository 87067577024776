<template>
  <div class="total board_box">
    <div class="board_title dp_f">
      <div class="title">入库合计</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content dp_f cur_p">
      <div class="item item1">
        <div class="top">
          <div class="num">{{orderInfo.stockOrder}}</div>
          <div class="text">入库订单</div>
        </div>
        <div class="bottom">

        </div>
      </div>
      <div class="item item2">
        <div class="top">
          <div class="num">{{orderInfo.stockNum}}</div>
          <div class="text">入库数量</div>
        </div>
        <div class="bottom">

        </div>
      </div>
      <div class="item item3">
        <div class="top">
          <div class="num">{{$filters.toYuan(orderInfo.stockPrice,'')}}</div>
          <div class="text">入库金额</div>
        </div>
        <div class="bottom">
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {stockTotal} from "@/utils/api/inbound/board.js";
import moment from 'moment'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const { proxy } = getCurrentInstance();
const unitId = ref(6);
const emit = defineEmits(["like"])
const { formData, isLike } = toRefs(props);
const orderInfo = reactive({
  stockOrder: 0, // 入库订单
  stockNum: 0, //入库数量
  stockPrice: 0, //入库金额
})
watch(
  () => formData.value,
  (val) => {
    getData()
  },{
    deep:true
  }
);
const getData = () => {
  stockTotal({
       beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
     orderInfo.stockOrder = res.data?.stockOrder || 0;
     orderInfo.stockNum = res.data?.stockNum || 0;
     orderInfo.stockPrice = res.data?.stockPrice || 0;
  })
};
getData()
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.total {
  width: 49.5%;
  height: 296px;
  .content {
    margin-top: 16px;
    .item {
      @include composing(3, 1%);
      width: 33%;
      height: 192px;
      padding: 22px;
      box-sizing: border-box;
      border-radius: 16px;
      &.item1 {
        background: url("../../../assets/img/board/board_total_1.png") center
          center no-repeat;
        background-size: cover;
      }
      &.item2 {
        background: url("../../../assets/img/board/board_total_2.png") center
          center no-repeat;
        background-size: cover;
      }
      &.item3 {
        background: url("../../../assets/img/board/board_total_3.png") center
          center no-repeat;
        background-size: cover;
      }
      .num {
        font-size: 24px;
        color: $fsColor;
        @include textOverflow(100%);
        font-weight: 600;
        .unit {
          font-size: 18px;
        }
      }
      .text {
        font-size: 14px;
        color: $fsColor2;
        margin-top: 10px;
      }
      .bottom {
        margin-top: 40px;
      }
    }
  }
}
</style>
