<template>
  <div class="goodsType board_box">
    <div class="board_title dp_f">
      <div class="title">入库类型占比</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content">
      <div :id="eChart" style="width: 100%; height: 200px" ></div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import {getCurrentInstance, nextTick, onMounted, ref, toRefs, watch} from "vue";
import {pieColor} from "./config.js";
import {stockClassify} from "@/utils/api/inbound/board.js";
import moment from "moment";
import {useEventListener} from "@utils/tool/event";
import {debounce} from "lodash";

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const { proxy } = getCurrentInstance();
const { formData, isLike } = toRefs(props);
const eChart = ref("eChart" + Date.now() + Math.random());
const tableData = ref([])
const unitId = ref(9);
const emit = defineEmits(["like"])
//获取列表数据
let myChart = null;
const initEchart = () => {
  let chartDom = document.getElementById(eChart.value);
  myChart = echarts.init(chartDom);
  let option;
  option = {
    tooltip: {
      trigger: "item",
      formatter: "{b}({d}%)",
    },
    color: pieColor,
    legend: {
      type: "scroll",
      orient: "vertical",
      right: "10%",
      top: 20,
      bottom: 20,
      itemGap: 15,
      data: tableData.value,
      itemWidth: 14,
      pageIconSize: [12, 12],
      pageButtonItemGap: 3,
      textStyle: {
        fontSize: 14,
      },
       formatter: function(name){
  　　　　return name.length>4?name.substr(0,4)+"...":name;
  　　},
      pageIcons: {
        vertical: [
          "path://M6.826,1.526 L0.366,9.386 C-0.481,10.417 0.230,11.998 1.542,11.998 L14.460,11.998 C15.772,11.998 16.483,10.417 15.636,9.386 L9.176,1.526 C8.561,0.778 7.441,0.778 6.826,1.526 Z",
          "path://M10.169,10.413 L16.629,2.598 C17.476,1.572 16.765,0.000 15.453,0.000 L2.535,0.000 C1.223,0.000 0.512,1.572 1.359,2.598 L7.818,10.413 C8.434,11.157 9.554,11.157 10.169,10.413 Z",
        ],
      },
    },
    series: [
      {
        name: "分类",
        type: "pie",
        radius: ["30%", "70%"],
        center: ["40%", "50%"],
        data: tableData.value,
        label: {
          fontSize: 14,
          width:100,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  option && myChart.setOption(option);
};
const getData = ()=>{
  stockClassify({
   beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      tableData.value = res?.data?.list || []
      tableData.value.forEach(item=>item.name = item.classifyName)
      initEchart()
  })
}
onMounted(() => {
  nextTick(() => {
    getData();
  });
});
useEventListener(window, 'resize', debounce(() => {
  if (myChart) myChart.resize()
}, 100))

watch(
  () => formData.value,
  (val) => {
    getData();
  },{
    deep:true
  }
);
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>
<script>
export default {
  name: "goodsType",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.goodsType {
  width: 49.5%;
  height: 296px;
  .content {
    width: 100%;
    margin-top: 16px;
     height: 198px;
  }
}
</style>
