import request from '../../http'
import {managerUrl} from '../../api'

//入库类型占比接口
export function stockType(data){
  return request({
    url: "/carton/product/stock/census/type",
    method:'post',
    data
  })
}

//入库合计接口
export function stockTotal(data){
  return request({
    url:  "/carton/product/stock/census/total",
    method:'post',
    data
  })
}


//入库产品类型分布
export function stockClassify(data){
  return request({
    url: "/carton/product/stock/census/classify",
    method:'post',
    data
  })
}

//入库供应商排名
export function stockSupplier(data){
  return request({
    url: "/carton/product/stock/census/supplier",
    method:'post',
    data
  })
}

//入库产品排名
export function stockProduct(data){
  return request({
    url:  "/carton/product/stock/census/product",
    method:'post',
    data
  })
}

//入库订top5
export function stockOrderTop(data){
  return request({
    url:  "/carton/product/stock/order/top",
    method:'post',
    data
  })
}

